<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>
<script>
	window.addEventListener('scroll', function(ev) {
		document.body.toggleAttribute('scroll', true)
		this.timer && clearTimeout(this.timer)
		this.timer = setTimeout(() => {
			document.body.toggleAttribute('scroll')
		}, 500)
	})
	export default {
		name: "app",
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isRouterAlive: true
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.isRouterAlive = true;
				})
			}
		}
	}
</script>
<style lang="less">
.el-message-box__wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-pager li.active{
  background-color: #0BCEB5 !important;
}
	body {
		margin: 0;
    padding: 0;
	}
  @media screen and (min-width: 960px) {
    .empty{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .htmlWap{
      display: none !important;
    }
    .appTitle{
      font-size: 20px;
      font-weight: bold;
      color: #0B0B0B;
      margin-bottom: 20px;
      text-align: center;

    }
    .appInpBox{
      width: 100%;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: 8px;
      padding: 13px;
      display: flex;
      align-items: center;
      input{
        flex: 1;
        border: none;
        outline: none;
        font-size: 14px;
      }
    }
    .appBtn{
      width: 100%;
      height: 46px;
      background: #0BCEB5;
      border-radius: 4px;
      text-align: center;
      line-height: 46px;
      font-size: 16px;
      font-weight: 400;
      margin-top: 30px;
      cursor: pointer;
    }
    .appBox{
      width: 550px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(172,177,192,0.5);
      border-radius: 20px;
      margin: 30px auto;
      padding: 20px 50px;
      padding-bottom: 44px;
      position: relative;
    }
    .appBlank{
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      background: #00000050;
      z-index: 99;
    }
  }
  @media screen and (max-width: 960px) {
    .empty{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 60vw;

      }
    }
    .appTitle{
      font-size: 16px;
      font-weight: bold;
      color: #0B0B0B;
      margin-bottom: 10px;
      text-align: center;

    }
    .appInpBox{
      width: 100%;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: 8px;
      padding: 13px;
      display: flex;
      align-items: center;
      input{
        flex: 1;
        border: none;
        outline: none;
        font-size: 14px;
      }
    }
    .appBtn{
      width: 100%;
      height: 46px;
      background: #0BCEB5;
      border-radius: 4px;
      text-align: center;
      line-height: 46px;
      font-size: 16px;
      font-weight: 400;
      margin-top: 30px;
    }
    .appBox{
      width: 90%;

      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(172,177,192,0.5);
      border-radius: 20px;
      margin: 30px auto;
      padding: 20px;
      padding-bottom: 44px;
      position: relative;
    }
    .appBlank{
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
      background: #00000050;
      z-index: 99;
    }
    .htmlPc{
      display: none !important;
    }
  }


	.u-flex {
		display: flex;
	}

	.u-flex-y-center {
		display: flex;
		align-items: center;
	}

	.u-flex-between {
		justify-content: space-between;
	}
  .u-flex-1{
    flex: 1;
  }
	* {
		box-sizing: border-box;


	}

</style>
