import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)
//push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
	return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
	return VueRouterReplace.call(this, to).catch(err => err)
}

const routes = [{
		path: '/',
		name: 'home',
		component: index
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue')

	},
	{
		path: '/list',
		name: 'list',
		component: () => import('../views/list.vue')

	},
	{
		path: '/proBox',
		name: 'proBox',
		component: () => import('../views/proBox.vue')

	},
	{
		path: '/boxSuccess',
		name: 'boxSuccess',
		component: () => import('../views/boxSuccess.vue')

	},
	{
		path: '/playBox',
		name: 'playBox',
		component: () => import('../views/playBox.vue')

	}
	,
	{
		path: '/rollRoom',
		name: 'rollRoom',
		component: () => import('../views/rollRoom.vue')

	},
	{
		path: '/roomDetail',
		name: 'roomDetail',
		component: () => import('../views/roomDetail.vue')

	},
	{
		path: '/promotion',
		name: 'promotion',
		component: () => import('../views/promotion.vue')

	},
	{
		path: '/certification',
		name: 'certification',
		component: () => import('../views/certification.vue')

	},
	{
		path: '/member',
		name: 'member',
		component: () => import('../views/member.vue')

	},
	{
		path: '/message',
		name: 'message',
		component: () => import('../views/message.vue')

	},
	{
		path: '/shop',
		name: 'shop',
		component: () => import('../views/shop.vue')

	},
	{
		path: '/detail',
		name: 'detail',
		component: () => import('../views/detail.vue')

	},
	{
		path: '/demo',
		name: 'demo',
		component: () => import('../views/demo.vue')

	},
	{
		path: '/orderCreate',
		name: 'orderCreate',
		component: () => import('../views/orderCreate.vue')

	}

]

const router = new VueRouter({
	 mode: 'hash',
	  base: process.env.BASE_URL,

	routes
})

export default router
