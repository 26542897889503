import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    //数据，相当于data
    state: {
        isLogin:false
    },
    getters: {

    },
    //里面定义方法，操作state方发
    mutations: {
        changeLogin(state,type){
            state.isLogin=type
        }
    },
    // 操作异步操作mutation
    actions: {

    },
    modules: {

    },
})
