<template>
  <div>
    <div class="htmlPc">


    <navBar ref="navBar" tab-index="0"/>

    <div class="swiper banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in banner">
          <img :src="item.image" style="width: 100%;height: 304px;object-fit: cover">
        </div>

      </div>
      <div class="swiper-button-prev">
        <img style="width: 27px;height: 27px" src="../assets/left.png"/>
      </div>
      <div class="swiper-button-next">
        <img style="width: 27px;height: 27px" src="../assets/right.png"/>
      </div>
    </div>
    <img src="../assets/toptiao.png" style="width: 100%;height: 56px"/>
    <div class="box">
      <div class="topName">
        <div class="name">福利roll房</div>
        <div class="line"></div>
      </div>
      <div class="grid2">
        <boxInfo v-for="item in roll" :data="item"/>
      </div>
      <div class="u-flex" style="justify-content: flex-end;margin-top: 20px">
        <div class="btn" @click="moreRoll">查看更多福利</div>
      </div>
    </div>
    <div style="background:#F7F6F3">
      <div class="box">
        <div class="topName">
          <div class="name">盲盒</div>
          <div class="line"></div>
        </div>
        <div class="bwBox">
          <div class="bwItem" v-for="item in box" @click="toDetail(item.id)">
            <img :src="item.image"/>
            <div class="lab"><span style="color: #0BCEB5">{{item.sold}}</span>人已开箱</div>
            <div class="info">
              <div class="nInfo">{{item.name}}</div>
              <div class="nPrice">キ{{item.price}}</div>
            </div>
          </div>
        </div>
        <div class="u-flex" style="justify-content: flex-end;margin-top: 20px">
          <div class="btn" @click="moreBox">查看更多盒子</div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="topNav">
        <div :class="type==0?'navAct':'nav'" @click="loadData(0)">今日最佳</div>
        <div :class="type==1?'navAct':'nav'" @click="loadData(1)">本周最佳</div>
        <div :class="type==2?'navAct':'nav'" @click="loadData(2)">本月最佳</div>
      </div>
      <div class="empty" v-if="rank.length==0">
        <img src="../assets/none.png" style="width: 50%"/>
      </div>
      <div v-if="rank.length>0">
      <div class="boxTop">
        <div v-if="two">
          <div class="tOne giftBox">
            <div class="gift">
              <div class="name">{{two.box_name}}</div>
              <div class="label" @click="toDetail(two.box_id)">幸运开箱</div>
            </div>
            <div class="proInfo">
              <img :src="two.image">
              <div class="proLabel">キ{{two.price}}</div>
            </div>
          </div>
          <div class="tInfo giftBox">
            <img src="../assets/two.png"/>
            <div style="margin-left: 20px">
              <div class="title">{{two.nickname}}</div>
              <div class="subTit">{{two.createtime}}</div>
            </div>
          </div>
        </div>
        <div v-else style="width: 342px"></div>
        <div>
          <div class="tTwo giftBox">
            <div class="gift">
              <div class="name">{{one.box_name}}</div>
              <div class="label" @click="toDetail(one.box_id)">幸运开箱</div>
            </div>
            <div class="proInfo">
              <img :src="one.image">
              <div class="proLabel">キ{{one.price}}</div>
            </div>
          </div>
          <div class="tInfo" style="background: #FFA42C ">
            <img src="../assets/one.png"/>
            <div style="margin-left: 20px">
              <div class="title">{{one.nickname}}</div>
              <div class="subTit">{{one.createtime}}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="tThree giftBox">
            <div class="gift">
              <div class="name">{{three.box_name}}</div>
              <div class="label" @click="toDetail(three.box_id)">幸运开箱</div>
            </div>
            <div class="proInfo">
              <img :src="three.image">
              <div class="proLabel">キ{{three.price}}</div>
            </div>
          </div>
          <div class="tInfo" style="background: #3294F0">
            <img src="../assets/three.png"/>
            <div style="margin-left: 20px">
              <div class="title">{{three.nickname}}</div>
              <div class="subTit">{{three.createtime}}</div>
            </div>
          </div>
        </div>

      </div>
      <div class="boxBottom">
        <div class="item" v-for="(item,index) in rank" v-if="index>2">
          <div class="left">
            <div class="num">{{index+1}}</div>
            <img :src="item.image" class="pro">
            <div class="iInfo">
              <div class="title">{{item.nickname}}</div>
              <div class="subTit">{{item.box_name}}</div>
              <div class="price">キ{{item.price}}</div>
            </div>
          </div>
          <div class="right">
            <div class="time">{{item.createtime}}</div>
            <div class="btn" @click="toDetail(item.box_id)">幸运开箱</div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div class="topDao">
      <div class="liBox">
        <img src="../assets/wt.png"/>
        <div style="color:#808080">进群交流</div>
        <div class="ewm">
          <img :src="wx_qrcode"/>
        </div>
      </div>
      <div class="liBox" @click="toLink">
        <img src="../assets/qq.png"/>
        <div style="color:#808080">联系客服</div>
      </div>
      <div class="liBox" @click="show=true">
        <img src="../assets/hong.png"/>
        <div style="color:#808080">口令红包</div>
      </div>
      <div class="liBox" @click="goTop">
        <img src="../assets/top.png"/>
      </div>
    </div>
    <navFooter/>
    <div class="appBlank u-flex-y-center" style="justify-content: center" v-if="show" @click="show=false">
      <div class="appBox" @click.stop>
        <div class="appTitle">红包口令</div>
        <div class="appInpBox">
          <input placeholder="请输入口令的key" v-model="sn"/>
        </div>
        <div class="appBtn" style="width: 182px;margin: 0 auto;margin-top: 28px" @click="sendSn">确定兑换</div>
      </div>
    </div>
    </div>
    <div class="htmlWap">
      <navBar tab-index="0"/>
      <div class="swiper banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in 10">
            <img src="../assets/banner.png" style="width: 100%;height: 134px;object-fit: cover">
          </div>
        </div>
      </div>
      <div class="box">
        <div class="topName u-flex-between">
          <div class="name">福利roll房</div>
          <div class="u-flex-y-center" style="font-size: 10px" @click="moreRoll">
            查看更多福利<img src="../assets/arrow-right.png" style="width: 12px;height: 12px"/>
          </div>
        </div>
        <div class="empty" v-if="roll.length==0">
          <img src="../assets/none.png" style="width: 50%"/>
        </div>
        <boxInfo v-for="item in roll" :data="item"/>
      </div>
      <div style="background:#F7F6F3">
        <div class="box">
          <div class="topName u-flex-between">
            <div class="name">盲盒</div>
            <div class="u-flex-y-center" style="font-size: 10px" @click="moreBox">
              查看更多盲盒<img src="../assets/arrow-right.png" style="width: 12px;height: 12px"/>
            </div>
          </div>
          <div class="empty" v-if="box.length==0">
            <img src="../assets/none.png" style="width: 50%"/>
          </div>
          <div class="bwBox">
            <div class="bwItem" v-for="item in box" @click="toDetail(item.id)">
              <img :src="item.image"/>
              <div class="lab"><span style="color: #0BCEB5">{{item.sold}}</span>人已开箱</div>
              <div class="info">
                <div class="nInfo">{{item.name}}</div>
                <div class="nPrice">キ{{item.price}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="box">
        <div class="bTitle">排行榜</div>
        <div class="topNav">
          <div :class="type==0?'navAct':'nav'" @click="loadData(0)">今日最佳</div>
          <div :class="type==1?'navAct':'nav'" @click="loadData(1)">本周最佳</div>
          <div :class="type==2?'navAct':'nav'" @click="loadData(2)">本月最佳</div>
        </div>
        <div class="empty" v-if="rank.length==0">
          <img src="../assets/none.png" style="width: 50%"/>
        </div>
        <div class="boxTop">
          <div v-if="two" style="position: relative">
            <div class="tOne giftBox" @click="toDetail(two.box_id)">
              <div class="gift">
                <div class="name">{{two.box_name}}</div>
                <div class="label" >幸运开箱</div>
              </div>
              <div class="proInfo">
                <img :src="two.image">
                <div class="proLabel">キ{{two.price}}</div>
              </div>
            </div>
            <div class="tInfo giftBox">
              <img src="../assets/two.png" style="top: 25px"/>
              <div>
                <div class="title">{{two.nickname}}</div>
              </div>
            </div>
          </div>
          <div v-if="one" style="position: relative">
            <div class="tTwo giftBox" @click="toDetail(one.box_id)">
              <div class="gift">
                <div class="name">{{one.box_name}}</div>
                <div class="label" >幸运开箱</div>
              </div>
              <div class="proInfo">
                <img :src="one.image">
                <div class="proLabel">キ{{one.price}}</div>
              </div>
            </div>
            <div class="tInfo" style="background: #FFA42C ">
              <img src="../assets/one.png"/>
              <div>
                <div class="title">{{one.nickname}}</div>
              </div>
            </div>
          </div>
          <div v-if="three" style="position: relative">
            <div class="tThree giftBox" @click="toDetail(three.box_id)">
              <div class="gift">
                <div class="name">{{three.box_name}}</div>
                <div class="label" >幸运开箱</div>
              </div>
              <div class="proInfo">
                <img :src="three.image">
                <div class="proLabel">キ{{three.price}}</div>
              </div>
            </div>
            <div class="tInfo" style="background: #3294F0">
              <img src="../assets/three.png" style="top: 30px"/>
              <div>
                <div class="title">{{three.nickname}}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="boxBottom">
          <div class="item" v-for="(item,index) in rank" v-if="index>3">
            <div class="left">
              <div class="num">{{index+1}}</div>
              <img :src="item.image" class="pro">
              <div class="iInfo">
                <div class="title">{{item.name}}</div>
                <div class="subTit">{{item.box_name}}</div>
                <div class="price">キ{{item.price}}</div>
              </div>
            </div>
            <div class="right">
              <div class="time">{{item.createtime}}</div>
              <div class="btn" @click="toDetail(item.box_id)">幸运开箱</div>
            </div>
          </div>
        </div>
      </div>
      <div class="appBlank u-flex-y-center" style="justify-content: center" v-if="show">
        <div class="appBox">
          <div class="appTitle">红包口令</div>
          <div class="appInpBox">
            <input placeholder="请输入口令的key"/>
          </div>
          <div class="appBtn" style="width: 182px;margin: 0 auto;margin-top: 28px">确定兑换</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import navFooter from '@/components/navFooter.vue'
import navBar from '@/components/navBar.vue'
import boxInfo from '@/components/boxInfo.vue'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default {
  components: {
    navBar, navFooter,boxInfo
  },
  data() {
    return {
      show:false,
      banner:[],
      box:[],
      roll:[],
      type:0,
      one:'',
      two:'',
      three:'',
      rank:[],
      sn:'',
      wx_qrcode:'',
      qqlink:''
    }
  },
  async mounted() {
    await this.loadData(0)
    const res=await this.http.fetchPost('/api/Index/index')
    this.banner=res.data.banner
    this.box=res.data.box
    this.roll=res.data.roll
    this.wx_qrcode=res.data.wx_qrcode
    this.qqlink=res.data.qq_link
    this.$nextTick(()=>{
      new Swiper('.banner', {
        loop: true,
        autoplay: true,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }
      })
    })
  },
  methods: {
    toLink(){
      window.open(this.qqlink, '_blank');
    },
    goTop(){
      window.scrollTo(0, 0)
    },
    async sendSn(){
      let data={
        sn:this.sn
      }
      const res=await this.http.fetchPost('/api/Order/redPack',data)
      if(res.code==1){
        this.$message.success('兑换成功')
        await this.$refs.navBar.loadData()
        this.show=false
      }else{
        this.$message.error(res.msg)
      }
    },
    moreRoll(){
      this.$router.push('rollRoom')
    },
    moreBox(){
      this.$router.push('list')
    },
    toDetail(id){
      this.$router.push({name:'proBox',query:{id}})
    },
    async loadData(type){
      this.one=''
      this.two=''
      this.three=''
      this.type=type
      this.rank=[]
      let data={
        type
      }
      const res=await this.http.fetchPost('/api/Index/ranks',data)
      if(res.data.length>0){
        this.one=res.data[0]
      }
      if(res.data.length>1){
        this.two=res.data[1]
      }
      if(res.data.length>2){
        this.three=res.data[2]
      }
      this.rank=res.data
    },

  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 960px) {
  .topDao{
    position: fixed;
    top: 420px;
    right: 10%;
    .liBox{
      width: 86px;
      height: 86px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(214,217,227,0.35);
      border-radius: 8px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      img{
        width: 46px;
        height: 46px;
      }
      &:hover{
        .ewm{
          display: block !important;
        }
      }
      .ewm{
        display: none;
        width: 200px;
        height: 200px;
        border-radius: 8px;
        background-color: #fff;
        position: absolute;
        right: 106px;
        box-shadow: 0px 0px 10px 0px rgba(214,217,227,0.35);
        padding: 10px;
        img{
          width: 180px;
          height: 180px;
        }
      }
    }
  }
  .giftBox {
    cursor: pointer;

    &:hover {
      .gift {
        display: block;

      }
    }
  }

  .gift {
    display: none;
    width: 342px;
    background: #00000050;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 10;

    padding: 30px;

    .name {
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 10px;
      text-align: center;
    }

    .label {
      width: 112px;
      height: 26px;
      background: #0BCEB5;
      border-radius: 4px;
      text-align: center;
      line-height: 26px;
      color: #FFFFFF;
      cursor: pointer;
      margin: 0 auto;
    }
  }

  .boxBottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;

    .item {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(214, 217, 227, 0.35);
      border-radius: 10px;
      padding: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .time {
          font-size: 12px;
          font-weight: 400;
          color: #BEBEBE;
        }

        .btn {
          margin-top: 30px;
          width: 78px;
          height: 26px;
          background: #0BCEB5;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #0B0B0B;
          text-align: center;
          line-height: 26px;
          cursor: pointer;
        }
      }

      .left {
        display: flex;
        align-items: center;

        .num {
          width: 60px;
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          color: #0B0B0B;
        }

        .pro {
          width: 90px;
          height: 86px;
          background: #FAF9F6;
          border-radius: 10px;
          object-fit: cover;
        }

        .iInfo {

          margin-left: 26px;

          .title {
            font-size: 18px;
            font-weight: 600;
            color: #0B0B0B;
          }

          .subTit {
            font-size: 16px;
            font-weight: 400;
            color: #0B0B0B;
          }

          .price {
            margin-top: 8px;
            font-size: 16px;
            font-weight: 600;
            color: #0BCEB5;
          }
        }
      }
    }
  }

  .boxTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    .tInfo {
      width: 342px;
      height: 62px;
      background: #0BCEB5;
      border-radius: 0px 0px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
      }

      .subTit {
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
      }

      img {
        width: 38px;
        height: 46px;
      }
    }

    .proInfo {
      width: 190px;
      height: 182px;
      background: #fff;
      border-radius: 10px;
      position: relative;
      margin-top: 24px;

      img {
        width: 190px;
        height: 182px;
        object-fit: cover;
      }

      .proLabel {
        position: absolute;
        bottom: 15px;
        left: 41px;
        width: 112px;
        height: 26px;
        background: #0BCEB5;
        border-radius: 4px;
        text-align: center;
        line-height: 26px;
      }
    }

    .tOne {
      width: 342px;
      height: 255px;
      background-image: url("../assets/ju3.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

    }

    .tThree {
      width: 342px;
      height: 248px;
      background-image: url("../assets/ju1.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-top: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .tTwo {
      width: 342px;
      height: 283px;
      background-image: url("../assets/ju2.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  .topNav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .navAct {
      width: 170px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      font-size: 16px;
      font-weight: 400;
      color: #0B0B0B;
      background-image: url("@/assets/tiaoed.png");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .nav {
      width: 170px;
      height: 38px;
      border-radius: 4px;
      text-align: center;
      line-height: 38px;
      font-size: 16px;
      font-weight: 400;
      color: #808080;
      background-image: url("@/assets/tiao.png");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .btn {
    width: 182px;
    height: 46px;
    background: #0BCEB5;
    border-radius: 4px;
    text-align: center;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    color: #0B0B0B;
    cursor: pointer;
  }

  .bwBox {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 34px 22px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 37px;

    .bwItem {
      width: 260px;
      height: 308px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(214, 217, 227, 0.35);
      border-radius: 8px;
      position: relative;

      .info {
        margin-top: 8px;
        padding: 0px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nInfo {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          flex: 1;
          font-size: 16px;
          font-weight: 400;
          color: #0B0B0B;
          margin-right: 10px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .nPrice {
          font-size: 14px;
          font-weight: 600;
          color: #0BCEB5;
        }
      }

      img {
        width: 260px;
        height: 260px;
        object-fit: cover;
      }

      .lab {
        position: absolute;
        padding: 0 10px;
        height: 24px;
        line-height: 24px;
        background: #FFFFFF40;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #808080;
        top: 8px;
        left: 8px;
      }
    }
  }

  .grid2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }

  .box {
    padding: 38px 0;
    max-width: 1200px;
    margin: 0 auto;

    .topName {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      .name {
        font-size: 28px;
        font-weight: 600;
        color: #0B0B0B;
      }

      .line {
        margin-left: 26px;
        width: 100%;
        flex: 1;
        height: 1px;
        background: #0BCEB5;
      }
    }
  }

  .swiper-button-next {
    right: 10%;

    &:after {
      content: '';
    }
  }

  .swiper-button-prev {
    left: 10%;

    &:after {
      content: '';
    }
  }

  .banner {
    width: 100%;
    height: 304px;
    position: relative;
    overflow: hidden;

  }

  .proBox {
    width: 100%;
    background: #F7F6F3;
    padding: 16px;

    .proItem {
      width: 186px;
      height: 176px;
      background: #FFFFFF;
      border-radius: 10px;
      cursor: pointer;
      overflow: hidden;

      .see {
        display: block;

        img {
          width: 186px;
          height: 114px;
          object-fit: cover;
        }
      }

      .none {
        display: none;
        position: relative;

        .nameBox {
          width: 186px;
          height: 35px;
          background: #00000050;
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          line-height: 35px;
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
        }

        img {
          width: 186px;
          height: 176px;
          object-fit: cover;
        }

      }

      &:hover {
        .see {
          display: none;
        }

        .none {
          display: block;

        }
      }

      .name {
        height: 38px;
        font-size: 14px;
        font-weight: 400;
        color: #0B0B0B;
        line-height: 20px;
        margin: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .giftBox {
    cursor: pointer;

    &:hover {
      .gift {
        display: block;

      }
    }
  }

  .gift {
    display: none;
    width: 342px;
    background: #00000050;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 10;

    padding: 30px;

    .name {
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 10px;
      text-align: center;
    }

    .label {
      width: 112px;
      height: 26px;
      background: #0BCEB5;
      border-radius: 4px;
      text-align: center;
      line-height: 26px;
      color: #FFFFFF;
      cursor: pointer;
      margin: 0 auto;
    }
  }

  .boxBottom {
    .item {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 0px 5px 0px rgba(214, 217, 227, 0.35);
      border-radius: 5px;
      padding: 6px 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        margin-left: 10px;

        .time {
          font-size: 10px;
          font-weight: 400;
          color: #BEBEBE;
          white-space: nowrap;
        }

        .btn {
          margin-top: 15px;
          width: 59px;
          height: 17px;
          background: #0BCEB5;
          border-radius: 2px;
          font-size: 10px;
          font-weight: 400;
          color: #0B0B0B;
          text-align: center;
          line-height: 17px;
        }
      }

      .left {
        display: flex;
        align-items: center;

        .num {
          width: 30px;
          text-align: center;
          font-size: 12px;
          color: #0B0B0B;
        }

        .pro {
          width: 55px;
          height: 52px;
          background: #FAF9F6;
          border-radius: 5px;
        }

        .iInfo {
          width: 100%;
          flex:1;
          margin-left: 6px;

          .title {
            width: 110px;
            font-size: 10px;
            color: #0B0B0B;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .subTit {
            font-size: 12px;
            font-weight: 400;
            color: #0B0B0B;
          }

          .price {
            margin-top: 4px;
            font-size: 10px;
            color: #0BCEB5;
          }
        }
      }
    }
  }

  .boxTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .tInfo {
      width: 108px;
      height: 26px;
      background: #0BCEB5;
      border-radius: 0px 0px 5px 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 10px;
        font-weight: 400;
        color: #FFFFFF;
      }

      .subTit {
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
      }

      img {
        position: absolute;
        width: 14px;
        height: 18px;
        top: 8px;
        left: 10px;
      }
    }

    .proInfo {
      width: 95px;
      height: 91px;
      background: #fff;
      border-radius: 5x;
      position: relative;
      margin-top: 12px;

      img {
        width: 95px;
        height: 91px;
        object-fit: cover;
      }

      .proLabel {
        position: absolute;
        bottom: 7px;
        left: 20px;
        width: 56px;
        height: 13px;
        background: #0BCEB5;
        border-radius: 2px;
        text-align: center;
        line-height: 13px;
        font-size: 8px;
      }
    }

    .tOne {
      width: 108px;
      height: 115px;
      background-image: url("../assets/ju3.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size:100% 115px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

    }

    .tThree {
      width: 108px;
      height: 115px;
      background-image: url("../assets/ju1.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size:100% 115px;
      margin-top: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .tTwo {
      width: 108px;
      height: 115px;
      background-image: url("../assets/ju2.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size:100% 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
  .bTitle{
    font-size: 18px;
    font-weight: bold;
    color: #0B0B0B;
    margin-bottom: 20px;
  }
  .topNav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .navAct {
      width: 85px;
      height: 19px;
      text-align: center;
      line-height: 19px;
      font-size: 10px;
      font-weight: 400;
      color: #0B0B0B;
      background-image: url("@/assets/tiaoed.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    .nav {
      width: 85px;
      height: 19px;
      text-align: center;
      line-height: 19px;
      font-size: 10px;
      font-weight: 400;
      color: #808080;
      background-image: url("@/assets/tiao.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  .btn {
    width: 182px;
    height: 46px;
    background: #0BCEB5;
    border-radius: 4px;
    text-align: center;
    line-height: 46px;
    font-size: 16px;
    font-weight: 400;
    color: #0B0B0B;
  }

  .bwBox {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;

    .bwItem {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(214, 217, 227, 0.35);
      border-radius: 8px;
      position: relative;

      .info {
        padding: 0px 9px;

        .nInfo {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          flex: 1;
          font-size: 14px;
          font-weight: 400;
          color: #0B0B0B;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .nPrice {
          font-size: 12px;
          font-weight: 600;
          color: #0BCEB5;
          margin-bottom: 10px;
          margin-top: 4px;
        }
      }

      img {
        width: 100%;
        height: 165px;
        object-fit: cover;
      }

      .lab {
        position: absolute;
        padding: 0 10px;
        height: 17px;
        line-height: 17px;
        background: #FFFFFF40;
        border-radius: 2px;
        font-size: 10px;
        font-weight: 400;
        color: #808080;
        top: 8px;
        left: 8px;
      }
    }
  }

  .grid2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }

  .box {
    padding: 20px 15px;

    .topName {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .name {
        font-size: 18px;
        font-weight: 600;
        color: #0B0B0B;
      }

      .line {
        margin-left: 26px;
        width: 100%;
        flex: 1;
        height: 1px;
        background: #0BCEB5;
      }
    }
  }



  .banner {
    width: 100%;
    height: 134px;
    overflow: hidden;
  }

  .proBox {
    width: 100%;
    background: #F7F6F3;
    padding: 16px;

    .proItem {
      width: 186px;
      height: 176px;
      background: #FFFFFF;
      border-radius: 10px;
      cursor: pointer;
      overflow: hidden;

      .see {
        display: block;

        img {
          width: 186px;
          height: 114px;
          object-fit: cover;
        }
      }

      .none {
        display: none;
        position: relative;

        .nameBox {
          width: 186px;
          height: 35px;
          background: #00000050;
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          line-height: 35px;
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
        }

        img {
          width: 186px;
          height: 176px;
          object-fit: cover;
        }

      }

      &:hover {
        .see {
          display: none;
        }

        .none {
          display: block;

        }
      }

      .name {
        height: 38px;
        font-size: 14px;
        font-weight: 400;
        color: #0B0B0B;
        line-height: 20px;
        margin: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
