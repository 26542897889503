<template>
  <div>
    <div class="htmlPc">
      <img :src="baseInfo.top_logo" style="width: 92px;height: 34px;cursor: pointer" @click="home()"/>
      <div class="nRight">
        <div class="navList">
          <div :class="tabIndex==0?'navItemAct':'navItem'" @click="home()">
            首页
          </div>
          <div :class="tabIndex==1?'navItemAct':'navItem'" @click="toHe()">盲盒</div>
          <div :class="tabIndex==2?'navItemAct':'navItem'" @click="roll()">福利roll房</div>
          <div :class="tabIndex==3?'navItemAct':'navItem'" @click="shop()">商城</div>
          <div :class="tabIndex==4?'navItemAct':'navItem'" @click="tuiGuang()">推广</div>
        </div>
        <div class="czBtn" v-if="$store.state.isLogin" @click="openCZ">充值</div>
        <div class="userInfo" style="position: relative" v-if="$store.state.isLogin" @click="showNav=!showNav">
          <div class="userNav" v-if="showNav" @click.stop>
            <div class="uItem" @click="navTo2(0)">
              <img src="../assets/n2.png"/>
              <div>个人信息</div>
            </div>
            <div class="uItem" @click="navTo2(1)">
              <img src="../assets/n3.png"/>
              <div>我的物品</div>
            </div>
            <div class="uItem" @click="navTo2(2)">
              <img src="../assets/n4.png"/>
              <div>充值记录</div>
            </div>
            <div class="uItem" @click="navTo2(4)">
              <img src="../assets/n5.png"/>
              <div>推广明细</div>
            </div>
            <div class="uItem" @click="navTo('certification')">
              <img src="../assets/n6.png"/>
              <div>实名认证</div>
            </div>
            <div class="uItem" @click="navTo('message')">
              <img src="../assets/n7.png"/>
              <div>通知</div>
            </div>
            <div class="uItem" @click="loginOut">
              <img src="../assets/n8.png"/>
              <div>退出登录</div>
            </div>
          </div>
          <img :src="info.avatar" class="head">
          <div class="info">
            <div class="name">{{ info.nickname }}</div>
            <div class="nei">
              <div class="price" style="margin-bottom: 0">キ{{ info.money }}</div>
              <div class="score">
                <img src="../assets/bs.png" style="width: 13px;height: 13px"/>
                <div style="margin-left: 6px">{{ info.score }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="userInfo" v-else @click="login">
          <img src="../assets/head.png" class="head">
          <div class="info">
            点击登录
          </div>
        </div>
      </div>

      <div class="appBlank u-flex-y-center" style="justify-content: center" v-if="showCZ" @click="showCZ=false">
        <div class="appBox" @click.stop>
          <template v-if="payType==0">
            <div class="appTitle">充值</div>
            <div class="tq">
              <div style="font-size: 14px;margin-right: 20px">上级推广码</div>
              <div class="inpt">
                <div class="left">
                  <input placeholder="请输入上级推广码" class="inp" v-model="invite_code"/>
                </div>
                <div class="right">修改</div>
              </div>
            </div>
            <div class="nameCz">请选择充值方式</div>
            <div class="zfb" @click="choosePay(0)">
              <img src="../assets/zfb.png"/>
              <div>支付宝支付</div>
            </div>
            <div style="margin: 10px 0">
              <el-checkbox v-model="isTrue">我已阅读并同意</el-checkbox>
              <span style="color: #0BCEB5" @click="openXY">《商城盲盒&服务协议》</span></div>
            <div style="color:red;font-size: 14px" v-if="!isTrue">请阅读并同意用户协议</div>
          </template>
          <template v-else-if="payType==1">
            <img src="@/assets/leftJt.png" class="arrowRight" @click="payType=0"/>
            <div class="appTitle">支付宝支付</div>
            <div class="nameCz">选择充值金额</div>
            <div class="price">货币换算：¥ 1 = キ{{ 1 * rate }}</div>
            <div class="gridCz">
              <div class="item" v-for="item in moneyList" @click="amount=item">キ{{ item }}</div>
            </div>
            <div class="tq" style="margin-bottom: 30px">
              <div style="font-size: 14px;margin-right: 20px">充值额度：キ</div>
              <div class="inpt">
                <div class="left">
                  <input placeholder="请输入充值额度" v-model="amount" class="inp"/>
                </div>
              </div>
              <div class="appBtn" style="width: 94px;margin:0 0 0 20px" @click="cpay">充值</div>
            </div>
          </template>
          <template v-else>
            <img src="@/assets/leftJt.png" class="arrowRight"/>
            <div class="appTitle">支付宝支付</div>
            <div class="nameCz">请用支付宝扫码以下二维码</div>
            <div class="u-flex-y-center">
              <img src="../assets/pro.png" style="width: 200px;
height: 200px;margin-right: 30px">
              <div style="font-size: 14px">使用支付宝扫一扫支付<br/>
                如遇商家限制可降低金额再次尝试
              </div>
            </div>
          </template>
          <div class="xianCz">
          </div>
          <div class="content" style="font-size: 14px" v-html="content">

          </div>
        </div>
      </div>
      <div class="appBlank u-flex-y-center" style="justify-content: center" v-if="showXY" @click="showXY=false">
        <div class="appBox" @click.stop>
          <div class="appTitle">商城盲盒&服务协议</div>
          <div class="content" v-html="content2">

          </div>
        </div>
      </div>
    </div>
    <div class="htmlWap">
      <div style="height: 44px"></div>
      <div class="navBar">
        <img src="../assets/closeBtn.png" class="nav" v-if="isNav" @click="isNav=!isNav"/>
        <img src="../assets/navWap.png" class="nav" v-else @click="isNav=!isNav"/>
        <img src="../assets/logo.png" class="logo"/>
        <div class="u-flex-y-center">
          <div class="appBtn" v-if="$store.state.isLogin"
               style="width: 60px;height: 30px;margin: 0;margin-right: 10px;border-radius: 60px;line-height: 30px;font-size: 14px"
               @click="openCZ">充值
          </div>
          <img :src="info.avatar" class="head" v-if="$store.state.isLogin" @click="showNav2=!showNav2"/>
          <img src="../assets/head.png" class="head" v-else @click="login"/>
        </div>
      </div>
      <div class="navPage" v-if="isNav">

        <div :class="tabIndex==0?'navItemsAct':'navItems'" @click="home()">
          首页
        </div>
        <div :class="tabIndex==1?'navItemsAct':'navItems'" @click="toHe()">盲盒</div>
        <div :class="tabIndex==2?'navItemsAct':'navItems'" @click="roll()">福利roll房</div>
        <div :class="tabIndex==3?'navItemsAct':'navItems'" @click="shop()">商城</div>
        <div :class="tabIndex==4?'navItemsAct':'navItems'" @click="tuiGuang()">推广</div>
      </div>
      <div class="appBlank u-flex-y-center" style="z-index: 10" v-if="showNav2" @click="showNav2=false">
        <div class="userNav" v-if="showNav2" @click.stop>
          <div class="uItem" @click="navTo2(0)">
            <img src="../assets/n2.png"/>
            <div>个人信息</div>
          </div>
          <div class="uItem" @click="navTo2(1)">
            <img src="../assets/n3.png"/>
            <div>我的物品</div>
          </div>
          <div class="uItem" @click="navTo2(2)">
            <img src="../assets/n4.png"/>
            <div>充值记录</div>
          </div>
          <div class="uItem" @click="navTo2(4)">
            <img src="../assets/n5.png"/>
            <div>推广明细</div>
          </div>
          <div class="uItem" @click="navTo2(3)">
            <img src="../assets/m4.png"/>
            <div>配送记录</div>
          </div>
          <div class="uItem" @click="navTo('certification')">
            <img src="../assets/n6.png"/>
            <div>实名认证</div>
          </div>
          <div class="uItem" @click="navTo('message')">
            <img src="../assets/n7.png"/>
            <div>通知</div>
          </div>
          <div class="uItem" @click="loginOut">
            <img src="../assets/n8.png"/>
            <div>退出登录</div>
          </div>
        </div>
      </div>
      <div class="appBlank u-flex-y-center" style="justify-content: center" v-if="showCZ" @click="showCZ=false">
        <div class="appBox" @click.stop>
          <template v-if="payType==0">
            <div class="appTitle">充值</div>
            <div class="tq">
              <div style="font-size: 14px;margin-right: 20px">上级推广码</div>
              <div class="inpt">
                <div class="left">
                  <input placeholder="请输入上级推广码" class="inp" v-model="invite_code"/>
                </div>
                <div class="right">修改</div>
              </div>
            </div>
            <div class="nameCz">请选择充值方式</div>
            <div class="zfb" @click="choosePay(0)">
              <img src="../assets/zfb.png"/>
              <div>支付宝支付</div>
            </div>
            <div style="margin: 10px 0;font-size: 12px">
              <el-checkbox v-model="isTrue">我已阅读并同意</el-checkbox>
              <span style="color: #0BCEB5" @click="openXY">《商城盲盒&服务协议》</span></div>
            <div style="color:red;font-size: 12px" v-if="!isTrue">请阅读并同意用户协议</div>
          </template>
          <template v-else-if="payType==1">
            <img src="@/assets/leftJt.png" class="arrowRight" @click="payType=0"/>
            <div class="appTitle">支付宝支付</div>
            <div class="nameCz">选择充值金额</div>
            <div class="price">货币换算：¥ 1 = キ{{ 1 * rate }}</div>
            <div class="gridCz">
              <div class="item" v-for="item in moneyList" @click="amount=item">キ{{ item }}</div>
            </div>
            <div class="tq" style="margin-bottom: 15px">
              <div style="font-size: 12px;margin-right: 10px;white-space: nowrap">充值额度：キ</div>
              <div class="inpt">
                <div class="left">
                  <input placeholder="请输入充值额度" v-model="amount" class="inp"/>
                </div>
              </div>

            </div>
            <div class="appBtn" style="font-size: 14px" @click="cpay">充值</div>
          </template>
          <template v-else>
            <img src="@/assets/leftJt.png" class="arrowRight"/>
            <div class="appTitle">支付宝 扫码支付</div>
            <div class="nameCz">请用支付宝扫码以下二维码</div>
            <div class="u-flex-y-center">
              <img src="../assets/pro.png" style="width: 200px;
height: 200px;margin-right: 30px">
              <div style="font-size: 14px">使用支付宝扫一扫支付<br/>
                如遇商家限制可降低金额再次尝试
              </div>
            </div>
          </template>
          <div class="xianCz">
          </div>
          <div class="content" style="font-size: 14px;height: 20vh;overflow: auto" v-html="content">

          </div>
        </div>
      </div>
      <div class="appBlank u-flex-y-center" style="justify-content: center" v-if="showXY" @click="showXY=false">
        <div class="appBox" @click.stop>
          <div class="appTitle">商城盲盒&服务协议</div>
          <div class="content" v-html="content2">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tabIndex'],
  name: 'navBar',
  data() {
    return {
      showCZ: false,
      checked: false,
      payType: 0,
      info: '',
      content: '',
      content2: '',
      moneyList: [],
      isTrue: false,
      showXY: false,
      rate: 1,
      paymethod: 0,
      amount: '',
      baseInfo: '',
      invite_code: '',
      showNav: false,
      isNav: false,
      showNav2: false
    }
  },
  async created() {
    await this.loadData()
  },
  methods: {
    navTo(url) {
      this.$router.push(url)
    },
    navTo2(index) {
      if (this.$route.name == "member") {
        this.$router.push({name: 'member', query: {type: index}})
        window.location.reload()
      } else {
        this.$router.push({name: 'member', query: {type: index}})
      }


    },
    loginOut() {
      localStorage.removeItem('token')
      if (this.$route.path == '/') {
        this.$router.push('/')
        window.location.reload()
      } else {
        this.$router.replace('/')
      }

      this.$store.commit('getLogin', false)
    },
    tuiGuang() {
      this.isNav = false
      this.$router.push('promotion')
    },
    toHe() {
      this.isNav = false
      this.$router.push('list')
    },
    //点击充值
    async cpay() {
      let url = window.location.pathname
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      let data = {
        amount: this.amount,
        invite_code: this.invite_code,

      }
      const res = await this.http.fetchPost('/api/Order/rechargeSubmit', data)
      data = {
        order_no: res.data.order_no,
        type: 2,
        method: 0,
        redirect_url: url,
        cate: isMobile ? 1 : 0
      }
      const res2 = await this.http.fetchPost('/api/Order/pay', data)
      const div = document.createElement('div')
      /* 下面的data.content就是后台返回接收到的数据 */
      div.innerHTML = res2.data.result

      document.body.appendChild(div)

      document.forms[0].submit()
    },
    //选择支付方式
    choosePay(type) {
      if (this.isTrue) {
        this.paymethod = type
        this.payType = 1
      } else {
        this.$message.error('请阅读并勾选协议')
      }

    },
    async openXY() {
      const res = await this.http.fetchPost('/api/Content/agree', {type: 'service'})
      this.content2 = res.data.content
      this.showXY = true
    },
    async openCZ() {
      const res = await this.http.fetchPost('/api/Order/rechargeConfig')
      this.rate = Number(res.data.recharge_rate)
      this.content = res.data.content
      this.moneyList = res.data.money
      this.showCZ = true
    },
    async loadData() {
      if (localStorage.getItem('token')) {
        this.$store.commit("changeLogin", true)
        const res = await this.http.fetchPost('/api/User/index')
        this.info = res.data
        this.invite_code = res.data.fid_code
      } else {
        this.$store.commit("changeLogin", false)
      }
      const res2 = await this.http.fetchPost('/api/Content/siteConfig')
      this.baseInfo = res2.data
    },
    member() {
      this.isNav = false
      this.$router.push('member')
    },
    home() {
      this.isNav = false
      this.$router.push('/')
    },
    shop() {
      this.isNav = false
      this.$router.push('shop')
    },
    login() {
      this.isNav = false
      this.$router.push('login')
    },
    roll() {
      this.isNav = false
      this.$router.push('rollRoom')
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 960px) {
  .content {
    font-size: 16px;
    font-weight: 400;
    color: #0B0B0B;
    line-height: 28px;
    max-height: 50vh;
    overflow: auto;
  }

  .userNav {
    width: 209px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(214, 217, 227, 0.35);
    border-radius: 10px;
    padding: 18px 16px;
    position: absolute;
    bottom: -324px;
    z-index: 10;

    .uItem {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #0B0B0B;
      padding: 10px 0;

      img {
        width: 18px;
        height: 18px;
        margin-right: 20px;
      }
    }
  }

  .arrowRight {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 38px;
    top: 25px;
  }

  .gridCz {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;

    .item {
      height: 42px;
      background: #FFFFFF;
      border: 1px solid #C6C6C6;
      border-radius: 6px;
      text-align: center;
      line-height: 42px;
      font-size: 16px;
      font-weight: 400;
      color: #808080;
      cursor: pointer;
    }

    .itemAct {
      height: 42px;
      background: #FFFFFF;
      border: 2px solid #0BCEB5;
      border-radius: 6px;
      text-align: center;
      line-height: 42px;
      font-size: 16px;
      font-weight: 400;
      color: #0BCEB5;
      cursor: pointer;
    }
  }

  .price {
    font-size: 14px;
    font-weight: 400;
    color: #0B0B0B;
    margin-bottom: 20px;
  }

  .nameCz {
    font-size: 18px;
    font-weight: 600;
    color: #0B0B0B;
    margin: 20px 0;
  }

  .xianCz {
    width: 100%;
    height: 1px;
    background: #E8E8E8;
    margin: 16px 0;
  }

  .zfb {
    width: 100%;
    height: 58px;
    background: #F7F6F3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 400;
    color: #0B0B0B;
    cursor: pointer;

    img {
      width: 28px;
      height: 28px;
      margin-right: 27px;
    }
  }

  .tq {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .inpt {
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      height: 42px;
      background: #FFFFFF;
      border: 1px solid #C6C6C6;
      border-radius: 6px;

      .left {
        display: flex;
        align-items: center;
        font-size: 16px;

        .inp {
          flex: 1;
          border: none;
          outline: none;
        }
      }

      .right {
        font-size: 14px;
        font-weight: 400;
        color: #0BCEB5;
      }
    }
  }

  .htmlPc {
    max-width: 1200px;
    margin: 0 auto;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nRight {
      display: flex;
      align-items: center;

      .userInfo {
        display: flex;
        align-items: center;
        cursor: pointer;

        .info {
          margin-left: 12px;

          .name {
            font-size: 18px;
            font-weight: 400;
            color: #0B0B0B;
          }

          .nei {
            display: flex;
            align-items: center;

            .price {
              font-size: 16px;
              font-weight: 600;
              color: #0BCEB5;
            }

            .score {
              margin-left: 12px;
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 600;
              color: #0B0B0B;
            }
          }
        }

        .head {
          width: 42px;
          height: 42px;
          background: #ECEAE2;
          border-radius: 50%;

        }
      }

      .czBtn {
        margin-left: 32px;
        margin-right: 40px;
        width: 60px;
        height: 30px;
        background: #0BCEB5;
        border-radius: 15px;
        font-size: 16px;
        font-weight: 400;
        color: #FEFEFE;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }

      .navList {
        display: flex;
        align-items: center;

        .navItemAct {
          font-size: 20px;
          font-weight: 400;
          color: #0BCEB5;
          margin: 0 20px;
          position: relative;
          cursor: pointer;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background: #0BCEB5;
            border-radius: 2px;
            bottom: -10px;
          }
        }

        .navItem {
          font-size: 20px;
          font-weight: 400;
          color: #0B0B0B;
          margin: 0 20px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .arrowRight {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 38px;
    top: 25px;
  }

  .gridCz {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;

    .item {
      height: 42px;
      background: #FFFFFF;
      border: 1px solid #C6C6C6;
      border-radius: 6px;
      text-align: center;
      line-height: 42px;
      font-size: 14px;
      font-weight: 400;
      color: #808080;
      cursor: pointer;
    }

    .itemAct {
      height: 42px;
      background: #FFFFFF;
      border: 2px solid #0BCEB5;
      border-radius: 6px;
      text-align: center;
      line-height: 42px;
      font-size: 14px;
      font-weight: 400;
      color: #0BCEB5;
      cursor: pointer;
    }
  }

  .price {
    font-size: 14px;
    font-weight: 400;
    color: #0B0B0B;
    margin-bottom: 20px;
  }

  .nameCz {
    font-size: 14px;
    font-weight: 600;
    color: #0B0B0B;
    margin: 10px 0;
  }

  .xianCz {
    width: 100%;
    height: 1px;
    background: #E8E8E8;
    margin: 16px 0;
  }
  .zfb {
    width: 100%;
    height: 48px;
    background: #F7F6F3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    color: #0B0B0B;
    cursor: pointer;

    img {
      width: 28px;
      height: 28px;
      margin-right: 27px;
    }
  }

  .tq {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .inpt {
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      height: 42px;
      background: #FFFFFF;
      border: 1px solid #C6C6C6;
      border-radius: 6px;
      font-size: 12px;

      .left {
        display: flex;
        align-items: center;
        font-size: 12px;

        .inp {
          flex: 1;
          border: none;
          outline: none;
        }
      }

      .right {
        font-size: 14px;
        font-weight: 400;
        color: #0BCEB5;
      }
    }
  }
  .userNav {
    width: 70vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255);
    padding: 15px;
    padding-top: 60px;
    right: 0;

    .uItem {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
      color: #0B0B0B;
      padding: 10px 0;

      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
    }
  }

  .navPage {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 90px;

    .navItems {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 196px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 46px;

      &:before {
        content: '';
        width: 36px;
        height: 2px;
        background: #000;
        border-radius: 1px;
      }

      &:after {
        content: '';
        width: 36px;
        height: 2px;
        background: #000;
        border-radius: 1px;
      }
    }

    .navItemsAct {
      display: flex;
      align-items: center;
      width: 196px;
      justify-content: space-between;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 46px;
      color: #0BCEB5;
      position: relative;

      &:before {
        content: '';
        width: 36px;
        height: 2px;
        background: #0BCEB5;
        border-radius: 1px;
      }

      &:after {
        content: '';
        width: 36px;
        height: 2px;
        background: #0BCEB5;
        border-radius: 1px;
      }
    }
  }

  .navBar {
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;

    .nav {
      width: 18px;
      height: 16px;
    }

    .logo {
      width: 70px;
      height: 26px;
    }

    .head {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}
</style>
