<template>
  <div>
    <div class="box" @click="toDetail(data.id)">
      <div class="top">
        <img :src="data.image" class="one">
        <div class="u-flex-1" style="width: calc(100% - 100px)">
          <div class="oneBox">
            <div class="rollN">
             {{data.desc}}
            </div>
          </div>
          <div class="twoGrid">
            <div class="proItem" v-for="item in data.goods_image">
              <img :src="item">
            </div>
            <div class="proItem" style="display: flex;align-items: center;justify-content: center" v-if="data.die_goods_num>0">
              +{{data.die_goods_num}}
            </div>
          </div>
        </div>

      </div>
      <div class="bottom">
        <div class="bLeft">
          <div class="label">
            キ{{data.total}}
          </div>
          <div class="label"><img src="../assets/pel.png" style="width: 12px;height: 12px;"/>{{data.use_num}}/{{data.number}}</div>
          <div class="time">开奖时间 {{data.open_time}}</div>
        </div>
        <div class="bRight" @click="toDetail(data.id)">
          进入房间
          <img src="../assets/arrow_right.png" style="width: 14px;height: 14px;margin-left: 10px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "boxInfo",
  props:['data'],
  methods:{
    toDetail(id){
      this.$router.push({name:'roomDetail',query:{id}})
    }
  }
}
</script>

<style scoped lang="less">
@media screen and (max-width: 960px) {
  .box {
    width:100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(172,177,192,0.5);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bRight {
        display: none;
      }

      .bLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .time {
          font-size: 10px;
          font-weight: 400;
          color: #BEBEBE;
        }

        .label {
          display: flex;
          align-items: center;
          padding: 0 7px;
          height: 15px;
          line-height: 15px;
          background: rgba(11, 206, 181, 0.1);
          border: 1px solid #0BCEB5;
          border-radius: 15px;
          font-size: 10px;
          font-weight: 600;
          color: #0BCEB5;
          margin-right: 8px;
        }
      }
    }

    .top {
      display: flex;
      align-items: flex-start;
      margin-bottom: 14px;

      .twoGrid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 5px;

        .proItem {
          width: 100%;
          height: 41px;
          background: #F7F6F3;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 43px;
            height: 41px;
            object-fit: cover;
          }
        }
      }

      .oneBox {
        padding: 6px 14px;
        width:100%;
        background: #F7F6F3;
        border-radius: 5px;
        margin-bottom: 8px;
      }

      .rollN {
        box-sizing: content-box;
        height: 28px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 10px;
        font-weight: 400;
        color: #0B0B0B;
      }

      .one {
        max-width: 84px;
        height: 136px;
        border-radius: 5px;
        object-fit: cover;
        margin-right: 5px;
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .box {
    width: 588px;
    height: 332px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(172, 177, 192, 0.5);
    border-radius: 20px;
    padding: 20px;

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bRight {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #0BCEB5;
        cursor: pointer;
      }

      .bLeft {
        display: flex;
        align-items: center;

        .time {
          margin-left: 4px;
          font-size: 12px;
          font-weight: 400;
          color: #BEBEBE;
        }

        .label {
          display: flex;
          align-items: center;
          padding: 0 14px;
          height: 24px;
          line-height: 24px;
          background: rgba(11, 206, 181, 0.1);
          border: 1px solid #0BCEB5;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 600;
          color: #0BCEB5;
          margin-right: 11px;
        }
      }
    }

    .top {
      display: flex;
      align-items: flex-start;
      margin-bottom: 14px;

      .twoGrid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 7px;

        .proItem {
          width: 86px;
          height: 82px;
          background: #F7F6F3;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 86px;
            height: 82px;
            object-fit: cover;
          }
        }
      }

      .oneBox {
        padding: 14px 18px;
        width: 364px;
        background: #F7F6F3;
        border-radius: 10px;
        height: 68px;
        margin-bottom: 16px;
      }

      .rollN {
        box-sizing: content-box;
        height: 42px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 16px;
        font-weight: 400;
        color: #0B0B0B;
      }

      .one {
        width: 168px;
        height: 254px;
        border-radius: 10px;
        object-fit: cover;
        margin-right: 16px;
      }
    }
  }
}
</style>
