import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import https from './api/api.js'
import locale from 'element-ui/lib/locale/lang/en'
import store from './store'
Vue.config.productionTip = false
Vue.prototype.http = https
Vue.use(ElementUI);



new Vue({
  router,store,
  render: h => h(App)
}).$mount('#app')
