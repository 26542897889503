<template>
  <div>
    <div class="htmlPc">
      <div class="topBox">
        <div class="numBox">
          <div class="numItem">
            <div class="one">
             {{baseInfo.open_num}}
            </div>
            <div class="two">开盒数量</div>
          </div>
          <div class="numItem">
            <div class="one">
              {{baseInfo.user_count}}
            </div>
            <div class="two">用户数量</div>
          </div>
          <div class="numItem">
            <div class="one">
              {{baseInfo.line_num}}
            </div>
            <div class="two">在线人数</div>
          </div>
        </div>

      </div>
      <div class="bottomBox">
        <div class="oneBox">
          <div>
            <img :src="baseInfo.footer_logo" style="width: 176px;height: 64px;margin-bottom: 48px">
            <div class="tit" style="margin-bottom: 10px">联系方式</div>
            <div class="tit2" style="margin-bottom: 30px">{{baseInfo.telephone}}</div>
            <div class="tit" style="margin-bottom: 10px">客服QQ</div>
            <div class="tit2">{{baseInfo.wx_qq}}</div>
          </div>
          <div>
            <div class="tit2" style="margin-bottom: 30px">条款与政策</div>
            <div class="tName" v-for="item in baseInfo.agreement" @click="toGZ(item.type_enum,item.name)">{{item.name}}</div>
          </div>
          <div>
            <div class="tit2" style="margin-bottom: 30px">支付方式</div>
            <div class="pay">
              <img src="../assets/zfbPc.png" style="margin-right: 40px">
            </div>
          </div>
        </div>
      </div>
      <div class="copy">
       {{baseInfo.beian}}
      </div>
    </div>
    <div class="htmlWap">

    </div>
    <div class="appBlank u-flex-y-center" style="justify-content: center" v-if="show" @click="show=false">
      <div class="appBox" @click.stop>
        <div class="appTitle">{{name}}</div>
        <div class="content" v-html="content">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navFooter",
  data(){
    return{
      baseInfo:'',
      content:'',
      show:false,
      name:''
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    async loadData(){
      const res=await  this.http.fetchPost('/api/Content/siteConfig')
      this.baseInfo=res.data
    },
    async toGZ(type,name){
      const res=await this.http.fetchPost('/api/Content/agree',{type})
      this.content=res.data.content
      this.show=true
      this.name=name
    }
  }
}
</script>

<style scoped lang="less">
.content{
  font-size: 16px;
  font-weight: 400;
  color: #0B0B0B;
  line-height: 28px;
  height: 50vh;
  overflow: auto;
}
.copy {
  background: #08111E;
  border-top: 1px solid #3A4A61;
  padding-top: 38px;
  padding-bottom: 90px;
  font-size: 14px;
  font-weight: 400;
  color: #90959C;
  text-align: center;
}
.bottomBox{
  background: #08111E;
  .oneBox{
    max-width: 1200px;
    margin: 0 auto;
    padding: 86px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .tName{
      font-size: 16px;
      font-weight: 400;
      color: #90959C;
      margin:15px 0;
      cursor: pointer;
    }
    .tit{
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
    }
    .tit2{
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
    }
    .pay{
      display: flex;
      align-items: center;
      img{
        width: 72px;
        height: 72px;
      }
    }
  }
}

.topBox{
    width: 100%;
    height: 106px;
    background: #0BCEB5;
     .numBox{
      max-width: 1200px;
      margin: 0 auto;
      height: 106px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .numItem{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .one{
          font-size: 36px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .two{
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
</style>
